<template>
  <div>
    
    <b-overlay :show="is_loading">
      <b-row>
        <b-col>
          <b-card>
            <div>
              <b-form  @submit.prevent="fetch(1)">

                <b-row>
       
                  <b-col v-if="$can('voir-marchand-concession', 'marchand') || $can('voir-marchand-global', 'marchand')">
                    <v-select :reduce="item => item.id" v-model="filter.concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>
                  </b-col>
                  <b-col v-if="$can('voir-marchand-concession', 'marchand') || $can('voir-marchand-site', 'marchand') || $can('voir-marchand-global', 'marchand')">
                    <v-select :reduce="item => item.id" v-model="filter.site_id" label="libelle" :options="$store.state.initialConfigModule.initialState.sites" placeholder="Rechercher par site"></v-select>
                  </b-col>
                  <b-col md="auto">
                    <b-button type="submit" variant="primary">Recherche</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <div class="m-2 d-flex justify-content-between">
          <b-button
              variant="primary"
              :to="{ name: 'creer-offre-marchand'}"
          >
            Créer une offre marchand
          </b-button>
          <div class="align-self-center">
            {{pagination.total}} offres trouvées
          </div>

        </div>

        <b-table v-if="items"
                 ref="selectableTable"
                 :fields="fields"
                 :items="items"

                 responsive
                 class="mb-0"

        >
          <template #cell(offre)="data">

            <h5>Offre N°{{data.item.id}} </h5>
            <div>Client : <span class="font-weight-bold">{{data.item.client.full_name}}</span></div>
            <div>Vendeur : <span class="font-weight-bold">{{data.item.vendeur.full_name}}</span></div>
            <div>Date de création : <span class="font-weight-bold">{{data.item.created_at}}</span></div>
      
          </template>
          <template #cell(vehicules)="data">
<!--            <div v-if="index <= 1" v-for="(item, index) in data.item.vehicules" class="vehicule-item">-->
<!--              <vehicule-overview :with-image="false" :vehicule="item.vehicule" />-->
<!--              <hr />-->
<!--            </div>-->
            <span>{{data.item.vehicules.length }} véhicules </span>
          </template>
          <template #cell(str_statut)="data">
            <b-badge :variant="data.item.str_statut.variant">
              {{data.item.str_statut.libelle}}
            </b-badge>
          </template>
          
          <template #cell(total)="data">
            {{(Math.abs(data.item.prix).toLocaleString('en'))}} Dhs
          </template>
          <template #cell(actions)="data">
            <feather-icon

                icon="EyeIcon"
                size="16"
                class="mx-1 cursor-pointer  "
                @click="$router.push({ name: 'editer-offre-marchand', params: { id: data.item.id }})"
            />
          </template>
        </b-table>


      </b-card>
    </b-overlay>

    <b-row v-if="!is_loading && items.length == 0">
      <b-col>
        <b-card class="text-center">
          Aucune offre trouvée
        </b-card>
      </b-col>
    </b-row>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";
import axios from "@/libs/axios";


export default {
  name: "OffreListPage",
  components: {
    VehiculeOverview,
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [ 'offre','vehicules','total', {
        key : 'str_statut',
        label : 'Statut'
      },{key: 'actions'}],
      items : [],
      pagination: {},
      current_page : 1,
      is_loading : false,
      clients : [],
      vendeurs : [],
      filter : {
        q : '',
        statut : null,
        client : null,
        vendeur : null
      }
    }
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetchClients(search, loading) {
      if (!search) {
        return false;
      }
      let _this = this;
      this.$store.dispatch('clientsModule/fetchClients', {
        'q': search
      }).then(function (response) {
        _this.clients = response.data;
      })
    },
    fetchVendeurs(search, loading) {
      if (!search) {
        return false;
      }
      let _this = this;
      this.$store.dispatch('userModule/fetchUtilisateurs', {
        'q': search
      }).then(function (response) {
        _this.vendeurs = response.data;
      })
      console.log(search, loading)
    },
    fetch(page){
      let _this = this;
      _this.is_loading = true;
      
      axios.get('/api/marchand', {
        params : {
          page : page,
          ..._this.filter
        }
      }).then(function(response){
        _this.items = response.data.data;
        _this.pagination = response.data.meta;
        _this.is_loading = false;
      });
      
      
    
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
.vehicule-item{
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
  }
}
</style>
